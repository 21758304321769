import React, { useEffect, useState, useRef } from "react";
import localization from "../../Localization";
import {
  ParamKeyValuePair,
  createSearchParams,
  useNavigate,
} from "react-router-dom";

interface Props {
  styleName: string;
  textColor: string;
  onChange: (language: string) => void;
}

export function LanguageSwitcher(prop: Props) {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const queryId = queryParameters.get("id");
  const queryLocal = queryParameters.get("local");

  const url = window.location.href;

  const btnEn = useRef<HTMLButtonElement>(null);
  const btnDe = useRef<HTMLButtonElement>(null);

  const [local, setLocal] = useState("de");

  useEffect(() => {
    var lang = localization.getLanguage();

    if (queryLocal == "de" || queryLocal == "en") {
      setLocal(queryLocal);
    } else {
      setLocal(lang);
    }
    //localization.setLanguage(lang);
    lang = localization.getLanguage();
    if (lang === "de") {
      btnDe.current!.disabled = true;
      btnEn.current!.disabled = false;
    } else {
      btnDe.current!.disabled = false;
      btnEn.current!.disabled = true;
    }
  });

  const SetLanguage = (language: string) => {
    localization.setLanguage(language);
    prop.onChange(language);

    setLocal(language);
    const params: ParamKeyValuePair[] = [
      ["id", queryId ?? ""],
      ["local", language],
    ];

    params[1][1] = language;

    navigate({
      pathname: "/feedback",
      search: `?${createSearchParams(params)}`,
    });
  };

  return (
    <div className={prop.styleName}>
      <button
        ref={btnDe}
        className="local-button"
        style={{ color: local === "de" ? "#ffb100" : prop.textColor }}
        onClick={() => SetLanguage("de")}
      >
        DE
      </button>
      <span> | </span>
      <button
        ref={btnEn}
        className="local-button"
        style={{ color: local === "en" ? "#ffb100" : prop.textColor }}
        onClick={() => SetLanguage("en")}
      >
        ENG
      </button>
    </div>
  );
}

function updateURLParameter(url: string, param: string, paramVal: string) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

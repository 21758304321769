import React from "react";

export function LegalLinks() {
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.pioneers.agency/legal"
        className="footer-links "
      >
        Privacy Policy
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.pioneers.agency/legal"
        className="footer-links "
      >
        Imprint / Legal Info
      </a>
    </div>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCZLV-CrRs4Gmd5V689rdHDRInrdyhuRC8",
  authDomain: "brand-crmform.firebaseapp.com",
  projectId: "brand-crmform",
  storageBucket: "brand-crmform.appspot.com",
  messagingSenderId: "236499922520",
  appId: "1:236499922520:web:7258ecc5ca17506894642d",
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

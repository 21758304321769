import React, { useEffect, useState } from "react";
import "./Homepage.css";
import logo from "../images/logo_black.svg";
import { Claim } from "../components/Homepage/claim";
import { LegalLinks } from "../components/Homepage/LegalLinks";
import LocalizedStrings from "react-localization";
import localization from "../Localization";
import { useNavigate } from "react-router-dom";

function Invalid() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const queryLocal = queryParameters.get("local");

    useEffect(() => {
        localization.setLanguage("en");
        if (queryLocal == "de" || queryLocal == "en")
            localization.setLanguage(queryLocal ?? "de");
        else localization.setLanguage("en");
    }, []);

    return (<div style={{ height: "100vh", width: "100vw", backgroundColor: "#F3F3F4" }}>
        <div
            style={{
                paddingTop: "7vh",
                marginRight: "4vw",
                paddingBottom: "7vh",
                marginLeft: "4vw",
            }}
        >
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.pioneers.agency/"
                className="logo w-inline-block"
            >
                <img
                    style={{ width: "8vw", minWidth: "80px" }}
                    src={logo}
                    alt="Info"
                ></img>
            </a>
        </div>
        <main className="main">
            <div className="center">
                <br></br>

                <h2>
                    <span style={{ color: "black" }}> {localization.invalid0}</span>
                    <br></br>

                    <span style={{ color: "black" }}> {localization.invalid1}</span>
                    <span style={{ fontWeight: "bold" }}> <a href="mailto:brand@pioneers.agency" style={{ color: "#ffb100" }}>{localization.contactMail}</a></span>
                    <span style={{ color: "black" }}> {localization.invalid2}</span>

                </h2>


            </div>
        </main>

        <footer className="footer">
            <Claim></Claim>
            <LegalLinks></LegalLinks>
        </footer>
    </div>);

}


export default Invalid;


import * as React from "react";
import Box from "@mui/material/Box";
import PaginationItem from "@mui/material/PaginationItem";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import { createTheme } from "@mui/material/styles";

function Col(onOpen: (value: string) => void, onCopy: (value: string, name:string) => void) {
  return [
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      sortable: true,
    },
    {
      field: "publishedAt",
      headerName: "Published At",
      width: 150,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      type: "number",
      width: 110,
      sortable: true,
    },
    {
      field: "view",
      headerName: "View",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <button
          className="button-40" style={{ backgroundColor: "#ffb100" }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={() => onOpen(params.id.toString())}
        >
          Open
        </button>
      ),
    },
    /*
    {
      field: "delete",
      headerName: "Delete",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <button
        className="button-40" style={{backgroundColor:"#ffb100"}}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={() => onOpen(params.id.toString())}
        >
          Delete
        </button>
      ),
    },
    */
    {
      field: "copy",
      headerName: "Copy Link",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (params: GridRenderCellParams<string>) => (
        <button
          className="button-40" style={{ backgroundColor: "#ffb100" }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={() => onCopy(params.id.toString(), "")}
        >
          Copy
        </button>
      ),
    },
  ];
}

const rows = [
  {
    id: 1,
    formId: "23u12i312h3",
    name: "Levis",
    createdAt: "22-03-2023",
    publishedAt: "22-03-2023",
    state: "open",
  },
  {
    id: 2,
    formId: "23u12iasdasd312h3",
    name: "Levis",
    createdAt: "22-03-2023",
    publishedAt: "22-03-2023",
    state: "open",
  },
];

interface Props {
  onCopy: (value: string, name:string) => void;
  onOpen: (id: string) => void;
  row: any[];
}
export default function DataGridDemo(props: Props) {
  var columns = Col(props.onOpen, props.onCopy);
  return (
    <Box sx={{ height: "600px", width: "100%" }}>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          borderBlockColor: "transparent",
          color: "white",
          text: { color: "#00ff11" },


          "& .MuiTablePagination-cell:hover": {
            color: "white",
          },
        }}
        rows={props.row}
        columns={columns}
        pageSize={25}
        components={{
          Pagination: CustomPagination,
        }}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb100",
    },
    secondary: {
      main: "#E33E7F",
    },
  },
});
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
    />
  );
}

import logoClaim from "../../images/pioneers_claim_black.svg";
export function Claim() {
    return <div className="claim">
        <img
            style={{ width: "8vw", minWidth: "80px" }}
            src={logoClaim}
            alt="Info"
        ></img>
    </div>;
}

import React from "react";
import { CSSTransition } from "react-transition-group";

interface Props {
  onSelection?: (isPressed: boolean) => void;
  column1: string;
  column2: string;
  isClicked?: boolean;
  enabled?: boolean;
  secondStyle?: boolean
}

interface State {
  isEnabled: boolean;
  isHovered: boolean;
  isClicked: boolean;
}
class ButtonTile extends React.Component<Props, State> {
  state: State = {
    isEnabled: false,
    isHovered: false,
    isClicked: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isEnabled: props.enabled != null ? props.enabled : true,
      isHovered: false,
      isClicked: props.isClicked != null ? props.isClicked : false,
    };
  }

  OnMouseOver = () => {
    this.setState({ isHovered: true });
    console.log(this.state.isHovered);
  };

  OnMouseOut = () => {
    if (!this.state.isClicked) this.setState({ isHovered: false });
  };

  OnClick = () => {
    //Callback
    if (this.props.onSelection != null)
      this.props.onSelection(!this.state.isClicked);
    this.setState({ isClicked: !this.state.isClicked });

  };

  render() {
    let buttonStyle = this.state.isClicked ? "Tile-btn-nohover" : "Tile-btn";
    if (!this.state.isEnabled) {
      if (this.state.isClicked) buttonStyle = "Tile-btn-disabled-isClicked";
      else buttonStyle = "Tile-btn-disabled";
    }

    let h1Button = this.props.secondStyle != null ? "tile-button-h1-B" :"tile-button-h1";
    let columnButton = this.props.secondStyle != null ? "Tile-btn-column2-B" :"Tile-btn-column2";

    return (
      <button
        className={buttonStyle}
        style={!this.state.isEnabled ? { cursor: "auto" , width : "100%"} : undefined}
        onMouseOver={this.state.isEnabled ? this.OnMouseOver : undefined}
        onMouseOut={this.state.isEnabled ? this.OnMouseOut : undefined}
        onMouseDown={this.state.isEnabled ? this.OnClick : undefined}
      >
            <div 
              className= {h1Button}
              style={{
                height:"50%",
                color:
                  this.state.isClicked || this.state.isHovered
                    ? "black"
                    : "#ffb100",
              }}
            >
              {this.props.column1}
            </div>
     
   
            <div
              className={columnButton}
              style={{
                height:"50%",
                color:
                  this.state.isClicked || this.state.isHovered
                    ? "black"
                    : "white",
              }}
            >
              {this.props.column2}
            </div>
 
       
      </button>
    );
  }
}

export default ButtonTile;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type FormDialogProps = {
  onConfirm: (name: string) => void;
};

type FormDialogState = {
  open: boolean;
  inputName: string;
};

class FormDialog extends React.Component<FormDialogProps, FormDialogState> {
  state = {
    open: false,
    inputName: "",
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onInputChange = (name: string) => {
    this.setState({ inputName: name });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Add Form</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a reference Name for a new Form.
            </DialogContentText>
            <TextField
              onChange={(event) => this.onInputChange(event.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Reference Name"
              type="email"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={() => this.state.inputName != "" ? this.props.onConfirm(this.state.inputName) : null}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FormDialog;

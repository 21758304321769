import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Homepage from "./pages/Homepage";
import { Overview } from "./pages/Overview";
import Form from "./models/data/form";
import Success from "./pages/Success";
import Invalid from "./pages/Invalid";
import { getAuth } from "firebase/auth";
import ProtectedRoute, { ProtectedRouteProps } from "./provider/FirebaseAuthContext";

interface Props {
  form: Form;
}


function App() {
  let auth = getAuth();

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: true,
    authenticationPath: '/login',
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Invalid />} />
        <Route path="/login" element={<Login />} />
        <Route path="/feedback" element={<Homepage />} />
        <Route path="/success" element={<Success />} />
        <Route path="/invalid" element={<Invalid />} />
        <Route path="/*" element={<Invalid />} />


        <Route path='/dashboard' element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Dashboard />} />} />
        <Route path='/overview' element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Overview />} />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;


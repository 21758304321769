import React, { useEffect, useState } from "react";
import TileButtonSmall from "./TileButtonSmall";
import localization from "../../Localization";

interface Element {
  id: string;
  isPressed: boolean;
  isDisabled?: boolean;
}

interface Props {
  elements: Element[];
  isEnabled?: true;
}

interface State {
  elements: Element[];
}

class Tilebuttons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log("update tilebuttons");
    this.state = {
      elements: props.elements,
    };
  }

  handlePress = (id: string) => {
    const updatedElements = this.state.elements.map((element) => {
      if (element.id === id) {
        return { ...element, isPressed: !element.isPressed };
      }
      return element;
    });
    this.setState({ elements: updatedElements });
  };

  render() {
    return (
      <div className="Select-Buttons-Grid">
        {this.state.elements.map((element) => (
          <TileButtonSmall
            key={element.id}
            isDisabled={element.isDisabled}
            isClicked={element.isPressed}
            column1={localization.getString(element.id)}
            onSelection={() => this.handlePress(element.id)}
          ></TileButtonSmall>
        ))}
      </div>
    );
  }
}

export default Tilebuttons;

import Snackbar from "@mui/material/Snackbar";
import React, { Component } from "react";

interface State {
  isSnackbarOpen: boolean;
  message: string;
}

class SnackbarComponent extends Component<{}, State> {
  state = {
    message: "Test",
    isSnackbarOpen: false,
  };

  handleSnackbarClose = () => {
    this.setState({ isSnackbarOpen: false });
  };

  openSnackbar = (message: string) => {
    this.setState({ message: message, isSnackbarOpen: true });
  };

  render() {
    return (
      <div>
        <Snackbar
          open={this.state.isSnackbarOpen}
          onClose={this.handleSnackbarClose}
          message={this.state.message}
        />
      </div>
    );
  }
}

export default SnackbarComponent;

import LocalizedStrings from "react-localization";

let localization = new LocalizedStrings({
  en: {
    welcomenormal1: "Y",
    welcomeWide1: "O",
    welcome1: "UR NEEDS —",
    welcomeWide2: "O",
    welcomeHelpful: "UR",
    welcomeEnd: "MISSION",
    welcome2: "What impressed you the most in 2022?",
    choose: "Choose from (multiple selection possible)",
    blockHeader1: "Presentation Design",
    blockText1: "Everything possible in PowerPoint",
    block1_1: "Image presentation",
    block1_2: "Event presentation",
    block1_3: "Interactive presentation",
    block1_4: "Polishing / refresh",
    block1_5: "Template, chart library",
    blockHeader2: "Brand Design",
    blockText2: "Extensive consulting and production",
    block2_1: "Logo design",
    block2_2: "Illustrations",
    block2_3: "Corporate materials",
    block2_4: "Branding development",
    block2_5: "Brand Guide",
    blockHeader3: "Digital Design",
    blockText3: "Integration of digital power into design",
    block3_1: "Content creation",
    block3_2: "Educational videos",
    block3_3: "Digital advertising",
    block3_4: "Social media assets",
    block3_5: "Event support",
    blockHeader4: "Campaign Design",
    blockText4: "Brand-infused, inspiring campaigns",
    block4_1: "Format adaption",
    block4_2: "Promotional assets",
    block4_3: "Production",
    block4_4: "Social media campaigns",
    block4_5: "CI adaptation",
    writefree: "Or tell us in your own words:",
    services: "What services and products would you like us to offer in 2023?",
    singleBlockHeader1: "Story-Telling",
    singeblocktext1: "Early involvement in planning, concept and story",
    singleBlockHeader2: "Campaign design",
    singeblocktext2: "Classic advertising and communication media, campaigns",
    singleBlockHeader3: "Digital assets",
    singeblocktext3:
      "Content for digital campaigns for social media, events etc.",
    singleBlockHeader4: "PowerPoint hotline",
    singeblocktext4:
      "Don‘t panic! Quick and specific instant support for your PPT questions",
    singleBlockHeader5: "Educational videos",
    singeblocktext5: "Complex concepts told in an approachable and engaging way",
    singleBlockHeader6: "TRAININGS",
    singeblocktext6:
      "Design meets function – modules for technical and creative trainings",
    feedback: "Space for your feedback, ideas, questions and criticism:",
    fineprint:
      'The fine print as usual: by clicking the "Send" button you are agreeing for us to use your answers for further evaluation.',
    confirm: "Send",
    intro1:
      "Trends & facts:",
    intro2:
      "Project spread 2022",
    success0: "T",
    success1: "hank you",
    success2: "for completing the survey!",
    success3: "We look forward to presenting you with our new creative products and processes after evaluating your responses.",
    invalid0:"We could not find the page you are looking for. Sorry!",
    invalid1:"Contact us via ",
    invalid2:", hopefully we can help further.",
    contactMail:"brand@pioneers.agency",
  },
  de: {
    welcomenormal1: "Y",
    welcomeWide1: "O",
    welcome1: "UR NEEDS —",
    welcomeWide2: "O",
    welcomeHelpful: "UR",
    welcomeEnd: "MISSION",
    welcome2: "Was hat Sie 2022 am meisten überzeugt?",
    choose: "Wählen Sie aus (Mehrfachauswahl möglich)",
    blockHeader1: "Presentation Design",
    blockText1: "Alles, was in PowerPoint möglich ist",
    block1_1: "Unternehmens-PPT",
    block1_2: "Event- / Keynote-PPT",
    block1_3: "Interaktive PPT",
    block1_4: "Polishing / Umstylen",
    block1_5: "Master, Chartpool",
    blockHeader2: "Brand Design",
    blockText2: "Umfangreiche Beratung, Entwicklung und Erstellung",
    block2_1: "Logoentwicklung ",
    block2_2: "Illustrationen",
    block2_3: "Corporate Materials",
    block2_4: "Weiterentw. Branding",
    block2_5: "Brand Guide",
    blockHeader3: "Digital Design",
    blockText3: "Integration der digitalen Kräfte in Designprodukte",
    block3_1: "Content-Erstellung",
    block3_2: "Erklärvideos",
    block3_3: "Digitale Werbung",
    block3_4: "Social Media Assets",
    block3_5: "Eventbetreuung",
    blockHeader4: "Campaign Design",
    blockText4: "Brand-inspirierte und inspirierende Kampagnen",
    block4_1: "Formatadaption",
    block4_2: "Werbemittel",
    block4_3: "Produktion",
    block4_4: "Social Media Kampagnen",
    block4_5: "Anpassung an CI",
    writefree: "Oder schreiben Sie frei:",
    services:
      "Welche Services/Produkte würden Sie sich von uns in 2023 wünschen?",
    singleBlockHeader1: "Story-Telling",
    singeblocktext1:
      "Früher Einstieg in die Planung, Konzeption und Entwicklung der Story",
    singleBlockHeader2: "Campaign Design",
    singeblocktext2:
      "Klassische Werbe- und Kommunikationsmittel, Kampagnenentwicklung",
    singleBlockHeader3: "Digital assets",
    singeblocktext3:
      "Content für digitale Kampagnen rund um Social Media, Events, etc.",
    singleBlockHeader4: "PPT-Hotline",
    singeblocktext4:
      "Don‘t panic! Schneller und gezielter Sofort-Support rund um Präsentationen",
    singleBlockHeader5: "Erklär-Videos",
    singeblocktext5:
      "Komplexe Konzepte verständlich und spannend durch Bewegtbild erklärt",
    singleBlockHeader6: "TRAININGS",
    singeblocktext6:
      "Design meets Function – Module für technische und gestalterische Trainings", feedback: "Raum für Feedback, Anregungen, Fragen und Kritik:",
    fineprint:
      "Das obligatorische Kleingedruckte: mit Klicken auf Absenden erklären Sie sich damit einverstanden, dass wir Ihre Antworten für die Auswertung speichern. ",
    confirm: "Absenden",
    intro1:
      "Trends & facts:",
    intro2:
      "Project spread 2022",
    success0: "V",
    success1: "ielen Dank",
    success2: "für das Ausfüllen unserer Umfrage!",
    success3: "Wir freuen uns darauf, Euch nach dem Auswerten Eurer Antworten unsere neuen kreativen Produkte und Prozesse zu präsentieren.",
    invalid0:"Leider konnten wir die gesuchte Seite nicht finden. Schade!",
    invalid1:"Kontaktieren Sie uns gerne unter ",
    invalid2:", wir helfen gerne weiter.",
    contactMail:"brand@pioneers.agency",

  },
});

export function SetLanguageToEng() {
  localization.setLanguage("en");

}

export function translateID(id: string) {
  localization.getString(id);
}
export default localization;

import { GridRow } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Form from "../models/data/form";
import { useParams } from "react-router-dom";
import { DeleteForm, GetForm } from "../firebase_calls/firebase_calls";
import ButtonTile from "../components/Homepage/TileButton";
import localization from "../Localization";
import Tilebuttons from "../components/Homepage/TileButtons";
import { padding } from "@mui/system";
import TilebuttonsLarge from "../components/Homepage/TileButtonsLarge";
import IconButton from "@mui/material/IconButton";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


interface Props {
  form: Form;
}

export function Overview() {
  const queryParameters = new URLSearchParams(window.location.search);
  const queryId = queryParameters.get("id");
  const queryLocal = queryParameters.get("local");
  const navigate = useNavigate();
  const [createdAtDate, setDate1] = useState<string>("");
  const [publishedAtDate, setDate2] = useState<string>("");

  const [form, setForm] = useState<Form>();
  const [block1, setBlock1] = useState<Element[]>([]);
  const [block2, setBlock2] = useState<Element[]>([]);
  const [block3, setBlock3] = useState<Element[]>([]);
  const [block4, setBlock4] = useState<Element[]>([]);

  const [singleBlock, setSingleBlocks] = useState<Element[]>([]);

  const tilebuttonsRef = React.createRef<Tilebuttons>();
  const tilebuttonsRef2 = React.createRef<Tilebuttons>();
  const tilebuttonsRef3 = React.createRef<Tilebuttons>();
  const tilebuttonsRef4 = React.createRef<Tilebuttons>();

  const singleBlocksRef = React.createRef<TilebuttonsLarge>();

  useEffect(() => {
    const asyncCallback = async () => {
      if (queryId != null) {
        await GetForm(queryId).then(async (data) => {
          console.log("got data");
          if (data != null) {
            console.log("got data2");
            setForm(data);
            Block1(data);
          }
        });
      }
    };

    asyncCallback();
  }, []);

  async function handleDelete() {
    if (form == null) return;
    await DeleteForm(form);

    navigate("/dashboard");
  }

  const params = useParams();
  interface Element {
    id: string;
    isPressed: boolean;
    isDisabled: boolean;
  }
  function Block1(form: Form) {
    if (form == null) return;

    var buttons1: Element[] = [];
    Object.keys(form.block1!).forEach((key) => {
      var value = form.block1![key as keyof typeof form.block1];
      buttons1.push({ id: key, isPressed: value, isDisabled: true });
    });
    buttons1.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    setBlock1(buttons1);
    tilebuttonsRef.current?.setState({ elements: buttons1 });

    var buttons2: Element[] = [];
    Object.keys(form.block2!).forEach((key) => {
      var value = form.block2![key as keyof typeof form.block2];
      buttons2.push({ id: key, isPressed: value, isDisabled: true });
    });
    buttons2.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    setBlock2(buttons2);
    tilebuttonsRef2.current?.setState({ elements: buttons2 });

    var buttons3: Element[] = [];
    Object.keys(form.block3!).forEach((key) => {
      var value = form.block3![key as keyof typeof form.block3];
      buttons3.push({ id: key, isPressed: value, isDisabled: true });
    });
    buttons3.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    setBlock3(buttons3);
    tilebuttonsRef3.current?.setState({ elements: buttons3 });

    var buttons4: Element[] = [];
    Object.keys(form.block4!).forEach((key) => {
      var value = form.block4![key as keyof typeof form.block4];
      buttons4.push({ id: key, isPressed: value, isDisabled: true });
    });
    buttons4.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    setBlock4(buttons4);
    tilebuttonsRef4.current?.setState({ elements: buttons4 });

    var singleBlock: Element[] = [];
    Object.keys(form.singleBlock!).forEach((key) => {
      var value = form.singleBlock![key as keyof typeof form.singleBlock];
      singleBlock.push({ id: key, isPressed: value, isDisabled: true });
    });
    singleBlock.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    setSingleBlocks(singleBlock);
    singleBlocksRef.current?.setState({ elements: singleBlock });


    var createdAt = new Date(Number(form?.createdAt)).toLocaleDateString();
    var publishedAt =
      form?.publishedAt != undefined
        ? new Date(Number(form?.publishedAt)).toLocaleDateString()
        : " - ";

    setDate1(createdAt);
    setDate2(publishedAt);
  }

  function FormValues() {
    if (form?.state != "published")
      return (<h3>The form has not yet been published</h3>);

    return (<div>
      <div className="Container-All-TileSelection">
        <div className="Container-TileSelection">
          <div className="Square-Tile">
            <ButtonTile
              column1={localization.blockHeader1}
              column2={localization.blockText1}
              isClicked={false}
              enabled={false}
            ></ButtonTile>
          </div>
          <Tilebuttons
            elements={block1}
            ref={tilebuttonsRef}
          ></Tilebuttons>
        </div>
        <div className="Container-TileSelection">
          <div className="Square-Tile">
            <ButtonTile
              column1={localization.blockHeader2}
              column2={localization.blockText2}
              isClicked={false}
              enabled={false}
            ></ButtonTile>
          </div>
          <Tilebuttons
            elements={block2}
            ref={tilebuttonsRef2}
          ></Tilebuttons>
        </div>

        <div className="Container-TileSelection">
          <div className="Square-Tile">
            <ButtonTile
              column1={localization.blockHeader3}
              column2={localization.blockText3}
              isClicked={false}
              enabled={false}
            ></ButtonTile>
          </div>
          <Tilebuttons
            elements={block3}
            ref={tilebuttonsRef3}
          ></Tilebuttons>
        </div>

        <div className="Container-TileSelection">
          <div className="Square-Tile">
            <ButtonTile
              column1={localization.blockHeader4}
              column2={localization.blockText4}
              isClicked={false}
              enabled={false}
            ></ButtonTile>
          </div>
          <Tilebuttons
            elements={block4}
            ref={tilebuttonsRef4}
          ></Tilebuttons>
        </div>


      </div>
      {TextField(form?.textfield1 != null ? form?.textfield1 : " - ", "", (value) => {
      })}

      <TilebuttonsLarge
        isEnabled={false}
        ref={singleBlocksRef}
        elements={singleBlock}
      ></TilebuttonsLarge>

      {TextField(form?.textfield2 != null ? form?.textfield2 : " - ", "", (value) => {
      })}

      <h2>Feedback</h2>
      {TextField(form?.textfield3 != null ? form?.textfield3 : " - ", "", (value) => {
      })}
    </div>
    )
  }



  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: " #00061c" }}>
      <div className="background">
        <div style={{ padding: "5vh" }}>
          <IconButton
            aria-label="arrowBack"
            color="primary"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack fontSize="inherit" />
          </IconButton>

          <h1 style={{ color: "white" }}>{form?.name}</h1>
          <div style={{ display: "flex" }}>
            <div
              style={{ flex: "69%", backgroundColor: "white", padding: "30px" }}
            >

              {FormValues()}

            </div>
            <div
              style={{
                marginLeft: "5px",
                marginRight: "5px",

                flex: "7px",

                backgroundColor: "#ffb100",
              }}
            ></div>
            <div style={{ flex: "29%" }}>
              <div
                style={{
                  borderColor: "#ffb100",
                  borderStyle: "solid",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontFamily: "Formula",
                    fontSize: "46px",
                  }}
                >
                  Information
                </div>
                <div style={{ color: "white" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                  >
                    <div style={{ flex: "50%" }}>
                      <div>Id</div>
                      <div>Name</div>
                      <div>State</div>
                      <div>Created By</div>
                      <div>Created At</div>
                      <div>Published At</div>
                    </div>
                    <div style={{ flex: "50%" }}>
                      <div>{form?.id}</div>
                      <div>{form?.name}</div>
                      <div>{form?.state}</div>
                      <div>{form?.createdBy}</div>
                      <div>{createdAtDate}</div>
                      <div>{publishedAtDate}</div>
                    </div>

                  </div>
                  <div style={{ paddingTop: "30px" }}>
                    <button onClick={() => { handleDelete() }} className="button-40" style={{ backgroundColor: "red" }}>Delete</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div></div>

  );
}


function TextField(initialValue: string, labelName: string, onChange: (value: string) => void) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "30px",
      }}
    >
      <h2>{labelName}</h2>

      <input
        className={"textfield"}
        value={initialValue}
        type="text"
        name="name"
        onChange={(value) => onChange(value.target.value)}
      />
    </div>
  );
}
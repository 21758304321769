import {
  collection,
  addDoc,
  getDocs,
  Firestore,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Form from "../models/data/form";
import form from "../models/data/form";

const auth = getAuth();

export async function signIn(email: string, password: string): Promise<string | null> {
  var userLogin = await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log("signed in");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      console.log(error);
      return error.message;
    });

    //setUser(userLogin);

    return null;
}

export async function CreateForm(refName: string) : Promise<Form | null>{
  if(auth.currentUser == null)
  {
    console.log("Not authenticated");
    return null;
  }
  var created = Date.now().toString();
  const formDoc = await addDoc(collection(db, "forms"), {
    name: refName,
    state: "open",
    createdAt: created,
    createdBy: auth.currentUser!.email,
    publishedAt: null,
    block1: null,
    block2: null,
    block3: null,
    singleBlock: null,
    textfield1: null,
    textfield2: null,
    textfield3: null,
  });
  const docRef = doc(db, "forms/" + formDoc.id);
  setDoc(docRef, { id: formDoc.id }, { merge: true });

  var backForm:Form = new Form(formDoc.id,refName,"open",false,created,auth.currentUser!.email ?? "");
  return backForm;
}

export const PostForm = async (form: Form) => {
  const docRef = doc(db, "forms/" + form.id);

  try {
    var dateNow = Date.now().toString();
    await setDoc(
      docRef,
      {
        state: "published",
        block1: form.block1 ? tsMapToJSObject(form.block1) : null,
        block2: form.block2 ? tsMapToJSObject(form.block2) : null,
        block3: form.block3 ? tsMapToJSObject(form.block3) : null,
        block4: form.block4 ? tsMapToJSObject(form.block4) : null,
        singleBlock: form.singleBlock
          ? tsMapToJSObject(form.singleBlock)
          : null,
        textfield1: form.textfield1,
        textfield2: form.textfield2,
        textfield3: form.textfield3,
        publishedAt: dateNow,
      },
      { merge: true }
    );
    return null;
  } catch (error) {
    return "error";
  }
};

export const DeleteForm = async (form: Form) => {
  const docRef = doc(db, "forms/" + form.id);
  try {
    await setDoc(
      docRef,
      {
        isDeleted: true,
      },
      { merge: true }
    );
    return null;
  } catch (error) {
    return "error";
  }
};

export const GetForm = async (id: string) => {
  const docRef = doc(db, "forms/" + id);
  var data = await getDoc(docRef);
  if (data.exists()) {
    var idForm = data.get("id");
    var name = data.get("name");
    var state = data.get("state");
    var isDeleted = data.get("isDeleted");
    var createdAt = data.get("createdAt");
    var createdBy = data.get("createdBy");
    var publishedAt = data.get("publishedAt");
    var block1 = data.get("block1");
    var block2 = data.get("block2");
    var block3 = data.get("block3");
    var block4 = data.get("block4");
    var singleBlock = data.get("singleBlock");
    var textfield1 = data.get("textfield1");
    var textfield2 = data.get("textfield2");
    var textfield3= data.get("textfield3");

    var form = new Form(
      idForm,
      name,
      state,
      isDeleted ?? null,
      createdAt,
      createdBy,
      publishedAt,
      block1,
      block2,
      block3,
      block4,
      singleBlock,
      textfield1,
      textfield2,
      textfield3
    );

    return form;
  }
  return null;
};

export const GetAllForms = async () => {
  if(auth.currentUser == null)
  {
    console.log("Not authenticated");
    return null;
  }
  const collectionRef = collection(db, "forms");
  const snapshot = await getDocs(collectionRef);
  const data = snapshot.docs.map((doc) => doc.data());

  var dataMap = new Map<string, Form>();

  snapshot.docs.forEach((doc) => {
    var data = doc.data();
    var form = new Form(
      data.id,
      data.name,
      data.state,
      data.isDeleted ?? null,
      data.createdAt,
      data.createdBy,
      data.publishedAt,
      data.block1,
      data.block2,
      data.block3,
      data.block4,
      data.singleBlock,
      data.textfield,
      data.textfield2,
      data.textfield3
    );

    dataMap.set(doc.data().id, form);
  });

  return dataMap;
};


// Cant figure out  how to use any Type to use it for any map.

function tsMapToJSObject(map: Map<string, boolean>) {
  // Create a new object to hold the key-value pairs from the Map
  const obj: { [key: string]: boolean } = {};

  // Iterate through the Map and add the key-value pairs to the object
  map.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
}

function tsMapToJSObjectForm(map: Map<string, Form>) {
  // Create a new object to hold the key-value pairs from the Map
  const obj: { [key: string]: Form } = {};

  // Iterate through the Map and add the key-value pairs to the object
  map.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
}
function setUser(userLogin: any) {
  throw new Error("Function not implemented.");
}


import React, { useEffect, useState } from "react";
import ButtonTile from "../components/Homepage/TileButton";
import "./Homepage.css";
import Tilebuttons from "../components/Homepage/TileButtons";
import {
  buttonRow1,
  buttonRow2,
  buttonRow3,
  buttonRow4,
  singleblock,
} from "../models/data/buttondata";
import ConfirmationButton from "../components/Homepage/ConfirmButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LocalizedStrings from "react-localization";
import localization from "../Localization";
import { LanguageSwitcher } from "../components/Homepage/LanguageSwitcher";

import imageDE from "../images/ProjectSpread_DE.png";
import imageEN from "../images/ProjectSpread_EN.png";
import logo from "../images/logo_black.svg";
import claim from "../images/pioneers_claim_black.svg";

import { stringify } from "querystring";
import Form from "../models/data/form";
import CircularProgress from "@mui/material/CircularProgress";
import { GetForm, PostForm } from "../firebase_calls/firebase_calls";
import TilebuttonsLarge from "../components/Homepage/TileButtonsLarge";
import { LegalLinks } from "../components/Homepage/LegalLinks";

const videoDE = "https://firebasestorage.googleapis.com/v0/b/brand-crmform.appspot.com/o/Kennzahlen_Loop_DE(1).mp4?alt=media&token=ac6f0734-6295-431b-9a51-f1b24165baaf";
const videoEN = "https://firebasestorage.googleapis.com/v0/b/brand-crmform.appspot.com/o/Kennzahlen_Loop_EN(1).mp4?alt=media&token=c8a02b38-0283-49a8-bb1b-ff132166cdc8";


function Home() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const queryId = queryParameters.get("id");
  const queryLocal = queryParameters.get("local");

  //Data
  const tilebuttonsRef = React.createRef<Tilebuttons>();
  const tilebuttonsRef2 = React.createRef<Tilebuttons>();
  const tilebuttonsRef3 = React.createRef<Tilebuttons>();
  const tilebuttonsRef4 = React.createRef<Tilebuttons>();
  const singeBlocksRef = React.createRef<TilebuttonsLarge>();

  const buttonData1 = buttonRow1;
  const buttonData2 = buttonRow2;
  const buttonData3 = buttonRow3;
  const buttonData4 = buttonRow4;
  const singeBlockData = singleblock;

  const [textfield1, setText1] = useState("");
  const [textfield2, setText2] = useState("");
  const [textfield3, setText3] = useState("");
  const [doesFormExist, setFormExist] = useState(false);
  const [imageContent, setImageContent] = useState(imageDE);
  const [videoContent, setVideoContent] = useState(videoDE);


  useEffect(() => {
    if (queryLocal == "de" || queryLocal == "en")
      localization.setLanguage(queryLocal ?? "de");
    else localization.setLanguage("de");

    const asyncCallback = async () => {
      if (localization.getLanguage() == "en") {
        setImageContent(imageEN)
        setVideoContent(videoEN);
      }

      if (queryId == null || queryId == "") {
        navigate({
          pathname: "/invalid",
          search: "?local=" + localization.getLanguage().toString(),
        });
        return;
      } else {
        var form = await GetForm(queryId);
        let isDeleted: boolean = form?.isDeleted != null ? form?.isDeleted : false;
        if (form == null || isDeleted) {
          navigate({
            pathname: "/invalid",
            search: "?local=" + localization.getLanguage().toString(),
          });
          return;
        }
        if (form.state == "published") {
          navigate({
            pathname: "/success",
            search: "?local=" + localization.getLanguage().toString(),
          });
          return;
        } else {
          setFormExist(true);
        }
      }
    };

    asyncCallback();
  }, []);

  const GetData = () => {
    console.log("getting data");
    const state = tilebuttonsRef.current?.state;

    let block1Data = new Map<string, boolean>();
    if (tilebuttonsRef.current?.state != null) {
      const elements = tilebuttonsRef.current?.state.elements;
      for (let index = 0; index < elements.length; index++) {
        block1Data.set(elements[index].id, elements[index].isPressed);
      }
    }

    let block2Data = new Map<string, boolean>();
    if (tilebuttonsRef2.current?.state != null) {
      const elements = tilebuttonsRef2.current?.state.elements;
      for (let index = 0; index < elements.length; index++) {
        block2Data.set(elements[index].id, elements[index].isPressed);
      }
    }
    let block3Data = new Map<string, boolean>();
    if (tilebuttonsRef3.current?.state != null) {
      const elements = tilebuttonsRef3.current?.state.elements;
      for (let index = 0; index < elements.length; index++) {
        block3Data.set(elements[index].id, elements[index].isPressed);
      }
    }
    let block4Data = new Map<string, boolean>();
    if (tilebuttonsRef4.current?.state != null) {
      const elements = tilebuttonsRef4.current?.state.elements;
      for (let index = 0; index < elements.length; index++) {
        block4Data.set(elements[index].id, elements[index].isPressed);
      }
    }

    let singleblockData = new Map<string, boolean>();
    if (singeBlocksRef.current?.state != null) {
      const elements = singeBlocksRef.current?.state.elements;
      for (let index = 0; index < elements.length; index++) {
        singleblockData.set(elements[index].id, elements[index].isPressed);
      }
    }

    var form = new Form(
      queryId!,
      "",
      "published",
      false,
      new Date(Date.now()).toString(),
      "",
      new Date(Date.now()).toString(),
      block1Data,
      block2Data,
      block3Data,
      block4Data,
      singleblockData,
      textfield1,
      textfield2,
      textfield3
    );

    return form;
  };

  const SubmitForm = async () => {
    var form = GetData();
    // can be also used for disabling our submit button
    setFormExist(false);
    await PostForm(form).then((value) => {
      setFormExist(true);
      if (value != null) {
        //Display error
      } else {
        navigate({
          pathname: "/success",
        });
      }
    });
  };

  function handleLanguageChange(languageCode: string) {
    languageCode == "de" ? setImageContent(imageDE) : setImageContent(imageEN);
    languageCode == "de" ? setVideoContent(videoDE) : setVideoContent(videoEN);


  }

  const blockPart1 = <div className="Container-All-TileSelection">
    <div className="Container-TileSelection">
      <div className="Square-Tile">
        <ButtonTile
          column1={localization.blockHeader1}
          column2={localization.blockText1}
          isClicked={false}
          enabled={false}
        ></ButtonTile>
      </div>
      <Tilebuttons
        elements={buttonData1}
        ref={tilebuttonsRef}
      ></Tilebuttons>
    </div>
    <div className="Container-TileSelection">
      <div className="Square-Tile">
        <ButtonTile
          column1={localization.blockHeader2}
          column2={localization.blockText2}
          isClicked={false}
          enabled={false}
        ></ButtonTile>
      </div>
      <Tilebuttons
        elements={buttonData2}
        ref={tilebuttonsRef2}
      ></Tilebuttons>
    </div>

    <div className="Container-TileSelection">
      <div className="Square-Tile">
        <ButtonTile
          column1={localization.blockHeader3}
          column2={localization.blockText3}
          isClicked={false}
          enabled={false}
        ></ButtonTile>
      </div>
      <Tilebuttons
        elements={buttonData3}
        ref={tilebuttonsRef3}
      ></Tilebuttons>
    </div>

    <div className="Container-TileSelection">
      <div className="Square-Tile">
        <ButtonTile
          column1={localization.blockHeader4}
          column2={localization.blockText4}
          isClicked={false}
          enabled={false}
        ></ButtonTile>
      </div>
      <Tilebuttons
        elements={buttonData4}
        ref={tilebuttonsRef4}
      ></Tilebuttons>
    </div>


  </div>;

  return (
    <div>
      <div className="row">
        <div className="col col-60">
          <div
            style={{
              paddingTop: "7vh",
              marginRight: "4vw",
              paddingBottom: "7vh",
              marginLeft: "4vw",
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pioneers.agency/"
              className="logo w-inline-block"
            >
              <img
                style={{ width: "8vw", minWidth: "80px" }}
                src={logo}
                alt="Info"
              ></img>
            </a>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LanguageSwitcher
                onChange={handleLanguageChange}
                styleName="local-switcher2"
                textColor="black"
              ></LanguageSwitcher>
              <div className="fade-in">
                <h3>
                  {localization.welcomenormal1}
                  <span className="wide">{localization.welcomeWide1}</span>
                  {localization.welcome1}

                  <br></br>
                  <span style={{ paddingLeft: "8vw" }}>
                    <span className="wide" >
                      {localization.welcomeWide2}
                    </span>
                    {localization.welcomeHelpful}
                  </span>
                  <span style={{ color: "black" }}> {localization.welcomeEnd}</span>
                </h3>
              </div>
            </div>
            <h1>{localization.welcome2} </h1>
            <h2>{localization.choose} </h2>
            {blockPart1}
            {TextField(localization.writefree, (value) => {
              setText1(value);
            })}
            <h1>{localization.services}</h1>
            <TilebuttonsLarge
              secondStyle={true}
              isEnabled={true}
              ref={singeBlocksRef}
              elements={singleblock}
            ></TilebuttonsLarge>
            {TextField("", (value) => {
              setText2(value);
            })}
            <h1>{localization.feedback}</h1>
            <div style={{ marginTop: "-40px" }}>
              {TextField("", (value) => {
                setText3(value);
              })}
            </div>
            <h2>{localization.fineprint} </h2>
            <ConfirmationButton
              isAwaiting={doesFormExist}
              text={localization.confirm}
              onClick={() => {
                SubmitForm();
              }}
            ></ConfirmationButton>{" "}
          </div>
          <div className="claim">
            <img
              style={{ width: "8vw", minWidth: "80px" }}
              src={claim}
              alt="Info"
            ></img>
          </div>
        </div>

        {/* Right Side */}
        <div className="col col-40">
          <div
            style={{
              paddingTop: "7vh",
              marginRight: "4vw",
              paddingBottom: "7vh",
              marginLeft: "4vw",
            }}
          >
            <div style={{}}>
              <LanguageSwitcher
                onChange={handleLanguageChange}
                styleName="local-switcher"
                textColor="white"
              ></LanguageSwitcher>
              <h3 className="intro">
                {localization.intro1} <br></br>{" "}
              </h3>
              <div>
                <video
                  style={{ maxWidth: "100%", margin: "0 auto" }}
                  loop
                  muted
                  autoPlay
                  playsInline
                  src={videoContent}
                />
                <div style={{ paddingTop: "30px" }}>
                  <h3 className="intro">
                    <span style={{ color: "white" }}>
                      {localization.intro2}{" "}
                    </span>{" "}
                  </h3>
                </div>
              </div>
              <img style={{ paddingTop: "0px" }} src={imageContent} alt="Info"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-60"></div>
        <div className="col col-40">{LegalLinks()}</div>
      </div>
    </div>
  );

  function TextField(labelName: string, onChange: (value: string) => void) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "30px",
        }}
      >
        <h2>{labelName}</h2>

        <input
          className={"textfield"}
          type="text"
          name="name"
          onChange={(value) => onChange(value.target.value)}
        />
      </div>
    );
  }
}

export default Home;

/*
  <div className="Container-TileSelection">
                <div className="Square-Tile">
                  <ButtonTile
                    column1={localization.blockHeader2}
                    column2={localization.blockText2}
                    isClicked={false}
                    enabled={false}
                  ></ButtonTile>
                </div>
                <Tilebuttons
                  elements={buttonData2}
                  ref={tilebuttonsRef2}
                ></Tilebuttons>
              </div>

              <div className="Container-TileSelection">
                <div className="Square-Tile">
                  <ButtonTile
                    column1={localization.blockHeader3}
                    column2={localization.blockText3}
                    isClicked={false}
                    enabled={false}
                  ></ButtonTile>
                </div>
                <Tilebuttons
                  elements={buttonData3}
                  ref={tilebuttonsRef3}
                ></Tilebuttons>
              </div>
*/
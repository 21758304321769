import React, { useEffect, useState } from "react";
import TileButtonSmall from "./TileButtonSmall";
import localization from "../../Localization";
import ButtonTile from "./TileButton";

interface Element {
  id: string;
  isPressed: boolean;

}

interface Props {
  elements: Element[];
  isEnabled: boolean;
  secondStyle?: boolean;
}

interface State {
  elements: Element[];
}

class TilebuttonsLarge extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      elements: props.elements,
    };
  }

  handlePress = (id: string) => {
    const updatedElements = this.state.elements.map((element) => {
      if (element.id === id) {
        return { ...element, isPressed: !element.isPressed };
      }
      return element;
    });
    this.setState({ elements: updatedElements });

    console.log("pressed");
  };

  render() {
    return (
      <div className="grid" style={{ width: "100%" }}>
        {this.state.elements.map((element) => (
          <div className="Square-Tile" style={{ width: this.props.isEnabled ? "100%" : "70%", maxWidth: "350px" }}>
            <ButtonTile
              secondStyle={this.props.secondStyle}
              enabled={this.props.isEnabled}
              key={element.id}
              isClicked={element.isPressed}
              column1={localization.getString(element.id)}
              column2={localization.getString(
                "singeblocktext" + element.id.charAt(element.id.length - 1)
              )}
              onSelection={() => this.handlePress(element.id)}
            ></ButtonTile>
          </div>
        ))}
      </div>
    );
  }
}

export default TilebuttonsLarge;

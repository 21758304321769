import { CircularProgress } from "@mui/material";
import React from "react";

interface Props {
  text: string;
  onClick: () => void;
  isAwaiting?: boolean;
}

function ConfirmationButton(props: Props) {
  var isAwaiting = props.isAwaiting ?? true;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {isAwaiting ? (
        <button
          onClick={() => props.onClick()}
          className="confirm-btn"
          type="button"
        >
          {props.text}
        </button>
      ) : (
        <button className="confirm-btn" type="submit" disabled>
          <CircularProgress color="inherit"></CircularProgress>
        </button>
      )}
    </div>
  );
}

export default ConfirmationButton;

import React from "react";

class Form {
  id: string;
  name: string;
  state:string;
  isDeleted?: boolean;
  createdAt: string;
  createdBy: string;
  publishedAt?: string;
  block1?: Map<string, boolean>;
  block2?: Map<string, boolean>;
  block3?: Map<string, boolean>;
  block4?: Map<string, boolean>;
  singleBlock?: Map<string, boolean>;
  textfield1?: string;
  textfield2?: string;
  textfield3?: string;


 constructor(
    id: string,
    name: string,
    state:string,
    isDeleted: boolean,
    createdAt: string,
    createdBy: string,
    publishedAt?: string,
    block1?: Map<string, boolean>,
    block2?: Map<string, boolean>,
    block3?: Map<string, boolean>,
    block4?: Map<string, boolean>,
    singleBlock?: Map<string, boolean>,
    textfield1?: string,
    textfield2?: string,
    textfield3?: string
  ) {
    this.id = id;
    this.name = name;
    this.state = state;
    this.isDeleted = isDeleted;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.publishedAt = publishedAt;
    this.block1 = block1;
    this.block2 = block2;
    this.block3 = block3;
    this.block4 = block4;

    this.singleBlock = singleBlock;
    this.textfield1 = textfield1;
    this.textfield2 = textfield2;
    this.textfield3 = textfield3;
  }

  toJSON() {
    return {
      firstName: this.name,
    };
  }
}

export default Form;

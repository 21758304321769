import React from "react";
interface Buttons {
  id: string;
  isPressed: boolean;
}

export const buttonRow1: Buttons[] = [
  { id: "block1_1", isPressed: false },
  { id: "block1_2", isPressed: false },
  { id: "block1_3", isPressed: false },
  { id: "block1_4", isPressed: false },
  { id: "block1_5", isPressed: false },
];

export const buttonRow2: Buttons[] = [
  { id: "block2_1", isPressed: false },
  { id: "block2_2", isPressed: false },
  { id: "block2_3", isPressed: false },
  { id: "block2_4", isPressed: false },
  { id: "block2_5", isPressed: false },
];

export const buttonRow3: Buttons[] = [
  { id: "block3_1", isPressed: false },
  { id: "block3_2", isPressed: false },
  { id: "block3_3", isPressed: false },
  { id: "block3_4", isPressed: false },
  { id: "block3_5", isPressed: false },
];

export const buttonRow4: Buttons[] = [
  { id: "block4_1", isPressed: false },
  { id: "block4_2", isPressed: false },
  { id: "block4_3", isPressed: false },
  { id: "block4_4", isPressed: false },
  { id: "block4_5", isPressed: false },
];

export const singleblock: Buttons[] = [
  { id: "singleBlockHeader1", isPressed: false },
  { id: "singleBlockHeader2", isPressed: false },
  { id: "singleBlockHeader3", isPressed: false },
  { id: "singleBlockHeader4", isPressed: false },
  { id: "singleBlockHeader5", isPressed: false },
  { id: "singleBlockHeader6", isPressed: false },
];

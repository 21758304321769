import "./Homepage.css";
import DataGridDemo from "../components/Homepage/DataTableComponent";
import React, { useEffect, useState } from "react";
import FormDialog from "../components/Homepage/DialogueComponent";
import { useRef } from "react";
import { CreateForm, GetAllForms } from "../firebase_calls/firebase_calls";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { elementAcceptingRef } from "@mui/utils";
import { forEach } from "typescript-collections/dist/lib/arrays";
import SnackbarComponent from "../components/Homepage/SnackbarContext";


function Dashboard() {
  type DataType = {
    id: string;
    name: string;
    createdAt: string;
    publishedAt: string;
    state: string;
  };
  const [forms, setForms] = useState<DataType[]>([]);
  const auth = getAuth();
  const snackbarRef = React.createRef<SnackbarComponent>();

  useEffect(() => {
    const asyncCallback = async () => {
      await GetForms();
    };

    asyncCallback();
  }, []);

  const dialogueRef = React.createRef<FormDialog>();
  const navigate = useNavigate();

  const OpenAddFormDialogue = () => {
    dialogueRef.current!.handleClickOpen();
  };

  const handleAddForm = (newForm: DataType) => {
    setForms(prevForms => [newForm, ...prevForms]);
  };

  const AddForm = async (referenceName: string) => {

    dialogueRef.current!.handleClose();

    await CreateForm(referenceName).then((value) => {
      if (value == null) return;
      var newForm: DataType = {
        id: value.id,
        name: value.name,
        createdAt: new Date(Number(value.createdAt)).toLocaleDateString(),
        publishedAt: value.publishedAt ?? " - ",
        state: "open",
      };
      handleAddForm(newForm);
    });

  };

  async function handleSignOut() {
    await auth.signOut();
    navigate("/login");
  }

  const GetForms = async () => {
    var data = await GetAllForms();
    if (data == null)
      return;


    // Iterate through the Map and add the key-value pairs to the object
    const allForms: DataType[] = [];

    data.forEach((value, key) => {
      let isDeleted: boolean = value.isDeleted != null ? value.isDeleted : false;
      console.log(isDeleted);
      if (!isDeleted) {
        var createdAt = new Date(Number(value.createdAt)).toLocaleDateString();
        var publishedAt = value.publishedAt != undefined
          ? new Date(Number(value.publishedAt)).toLocaleDateString()
          : " - ";
        let newForm = {
          id: value.id,
          name: value.name,
          createdAt: createdAt,
          publishedAt: publishedAt,
          state: value.state,
        };

        allForms.push(newForm);
      }
    });
    setForms(allForms);
  };

  const OnCopy = (id: string, name: string) => {
    const url = window.location.host;
    var copyUrl = "https://" + url + "/feedback?id=" + id + "&local=de";
    var formElement = forms.filter(x => x.id == id)[0];
    var elementName = formElement != null ? formElement.name : "";

    navigator.clipboard.writeText(copyUrl);
    snackbarRef.current?.openSnackbar(elementName + " Copied!");
  };

  const OnOpen = (id: string) => {
    const url = window.location.host;
    var path = url + "/overview?id=" + id + "&local=de";
    navigate({
      pathname: "/overview",
      search: "?id=" + id,
    });
  };

  return (
    <div className="background" style={{ height: "100vh", width: "100vw" }}>
      <div style={{ padding: "5vh" }}>
        <h1 style={{ color: "white" }}>Forms</h1>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingBottom: "10px" }}>
          <button className="button-40" style={{ backgroundColor: "#ffb100" }} onClick={() => handleSignOut()}> Sign Out</button>
          <button className="button-40" style={{ backgroundColor: "#ffb100" }} onClick={() => OpenAddFormDialogue()}>+ Add Form</button>
        </div>


        <FormDialog ref={dialogueRef} onConfirm={(name) => AddForm(name)} />
        <div
          style={{ display: "flex", border: "solid", borderColor: "#ffb100" }}
        >
          <DataGridDemo
            onOpen={(id) => OnOpen(id)}
            onCopy={(v, name) => OnCopy(v, name)}
            row={forms}
          ></DataGridDemo>
        </div>
      </div>
      <div>
        <SnackbarComponent ref={snackbarRef}></SnackbarComponent>
      </div>
    </div>
  );
}

export default Dashboard;

import React from "react";
import { CSSTransition } from "react-transition-group";

interface Props {
  onSelection: (isPressed: boolean) => void;
  column1: string;
  isClicked: boolean;
  isDisabled?: boolean;
}

interface State {
  isHovered: boolean;
  isClicked: boolean;
}
class TileButtonSmall extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isHovered: false,
      isClicked: props.isClicked,
    };
  }

  state: State = {
    isHovered: false,
    isClicked: false,
  };

  OnMouseOver = () => {
    if (this.props.isDisabled) return;
    if (!this.state.isClicked) this.setState({ isHovered: true });
  };

  OnMouseOut = () => {
    if (this.props.isDisabled) return;

    if (!this.state.isClicked) this.setState({ isHovered: false });
  };

  OnClick = () => {
    if (this.props.isDisabled) return;

    //Callback
    this.props.onSelection(!this.state.isClicked);
    this.setState({ isClicked: !this.state.isClicked });
  };

  render() {
    let buttonStyle = this.state.isClicked
      ? "Tile-btn-small-nohover"
      : "Tile-btn-small";

    return (
      <div className="Select-Btn-Small" >
        <button
          className={buttonStyle}
          style={{width:"100%"}}
          onMouseOver={this.OnMouseOver}
          onMouseOut={this.OnMouseOut}
          onMouseDown={this.OnClick}
        >
          <span
            style={{
              color:
                this.state.isClicked || this.state.isHovered
                  ? "black"
                  : "#ffb100",
            }}
          >
            {this.props.column1}
          </span>
        </button>
      </div>
    );
  }
}

export default TileButtonSmall;

import React, { useEffect, useState } from "react";
import "./Homepage.css";
import ConfirmationButton from "../components/Homepage/ConfirmButton";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { signIn } from "../firebase_calls/firebase_calls";
import Snackbar from "@mui/material/Snackbar";
import SnackbarComponent from "../components/Homepage/SnackbarContext";

// https://hirukarunathilaka.medium.com/signup-form-with-real-time-validation-using-react-typescript-6a7dfb3122b5

interface Props {
  email: string;
  password: string;
}

const Login = () => {
  const auth = getAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const snackbarRef = React.createRef<SnackbarComponent>();

  useEffect(() => {
    console.log("current user: " + auth.currentUser);
    if (auth.currentUser != null) {
      //navigate('/dashboard');
    }

    //setPassword("Karifa#88");
    //setEmail("tl@pioneers.agency");
    //handleSubmit();

  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  async function handleSubmit() {

    if (email == "" || password == "") {
      var error = `Some fields are empty`;
      snackbarRef.current?.openSnackbar(error);
      return;
    }
    console.log("Logged in successfully");
    await signIn(email, password).then((error) => {
      if (error != null) {
        console.log(error);
        snackbarRef.current?.openSnackbar(error);
        return;
      }
      console.log("Logged in successfully");
    });

    navigate('/dashboard');

  };

  return (
    <div className="background" style={{ height: "100vh", width: "100vw" }}>
      <div className="center-container">
        <div className="login-container">
          <div
            style={{
              padding: "2vh",
              width: "100%"
            }}
          >
            <h1>BRAND FORM</h1>
            <form>
              <input
                className="form-field"
                type="email"
                placeholder="E-Mail"
                value={email}
                onChange={handleEmailChange}
              />

              <br />
              <input
                className="form-field"
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <br />
 
              <ConfirmationButton
                text="Login"
                onClick={() => { handleSubmit() }}
              ></ConfirmationButton>
            </form>
          </div>
        </div>
      </div>
      <div>
        <SnackbarComponent ref={snackbarRef}></SnackbarComponent>
      </div>
    </div>
  );
}





export default Login;

import React, { useEffect } from "react";
import "./Homepage.css";
import logo from "../images/logo_black.svg";
import { Claim } from "../components/Homepage/claim";
import { LegalLinks } from "../components/Homepage/LegalLinks";
import localization from "../Localization";


function Success() {
    const queryParameters = new URLSearchParams(window.location.search);
    const queryLocal = queryParameters.get("local");
    useEffect(() => {
        if (queryLocal == "de" || queryLocal == "en")
            localization.setLanguage(queryLocal);
        else localization.setLanguage("de");
    }, []);

    return (<div style={{ height: "100vh", width: "100vw",  backgroundColor: "#F3F3F4" }}>
        <div
            style={{
                paddingTop: "7vh",
                marginRight: "4vw",
                paddingBottom: "7vh",
                marginLeft: "4vw",
            }}
        >
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.pioneers.agency/"
                className="logo w-inline-block"
            >
                <img
                    style={{ width: "8vw", minWidth: "80px" }}
                    src={logo}
                    alt="Info"
                ></img>
            </a>
        </div>
        <main className="main">
            <div className="center">
                <h3>
                    <span className="wide">{localization.success0}</span>
                    {localization.success1}
                </h3>
                <br></br>
       
                    <h2>
                    <span style={{ color: "black" }}> {localization.success2}</span>
                    <br></br>
             
                        <span style={{ color: "black" }}> {localization.success3}</span>
                        </h2>
    

            </div>
        </main>

        <footer className="footer">
            <Claim></Claim>
            <LegalLinks></LegalLinks>
        </footer>
    </div>);

}





export default Success;

